


<div class="p-1">

  <mat-card class="p-0">
      <h2 class="p-1">Tax Posting</h2>
      <form [formGroup]="form">
        <div class="row-section">
          <div class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Year</mat-label>
              <input type="number" matInput placeholder="year" formControlName="year" required>
              <mat-error *ngIf="form.controls.year.errors?.required">Year is required</mat-error>
            </mat-form-field>
           </div>
           <div class="px-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Quarter</mat-label>
                <mat-select formControlName="quarter">
                    <mat-option *ngFor="let _ of [].constructor(4); let i=index" [value]="i+1">
                      {{i+1}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.controls.quarter.errors?.required">Quarter is required</mat-error>
            </mat-form-field>
          </div>
          <div class="mat-cell">
            <button *ngIf="!isViewLoading" mat-raised-button color="primary"  (click)="view()" class="w-20" style="margin-top: 10px;">
               VIEW
          </button>
          <button  *ngIf="isViewLoading" mat-raised-button color="primary" class="w-20" style="margin-top: 10px;">
            <mat-icon><mat-spinner diameter="20" color="accent">
            </mat-spinner></mat-icon>
        </button>
          </div>
        </div>
      </form>
      <mat-divider></mat-divider>
      <div class="mat-table admin-table">
          <div class="mat-header-row">
              <div class="mat-header-cell">Tax Properties</div>
              <div class="mat-header-cell">Total Amount to Post</div>
              <div class="mat-header-cell"></div>
          </div>
          <button class="loading"  *ngIf="loading" color="primary">
              <app-loading-spinner></app-loading-spinner>
           </button>
          <div *ngIf="!loading" class="mat-row">
            <div class="mat-cell">{{noProperties}}</div>
            <div class="mat-cell">{{amountToPost}}</div>
            <div class="mat-cell">
              <button  *ngIf="!isPostLoading && noProperties!=0" mat-raised-button color="primary"  (click)="postTax()" class="w-20">
                 POST NOW
            </button>
            <button  *ngIf="isPostLoading" mat-raised-button color="primary" class="w-20">
              <mat-icon><mat-spinner diameter="20" color="accent">
              </mat-spinner></mat-icon>
          </button>
            </div>
          </div>
      </div>
  </mat-card>


</div>
