import { Component, OnInit } from '@angular/core';
import { Property } from './property';
import { PropertiesService } from './properties.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { PropertyDialogComponent } from './property-dialog/property-dialog.component';
import { ZonesService } from '../zones/zones.service';
import { DistrictsService } from '../districts/districts.service';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent implements OnInit {

  searchKey:string=''
  searchList:Property[]=[]
  public settings:Settings;
  constructor(public propertiesService:PropertiesService,private zoneService:ZonesService,private districtService:DistrictsService,public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.zoneService.getZones()
    this.districtService.getDistricts()
    this.propertiesService.getProperties()
  }


  getZone(zoneId:number){
    const zone=this.zoneService.ZoneList.find(x=>x.Zone_id==zoneId)
    return zone!=null?zone.Zone_Name:'Not Found'
   }
   getDistrict(districtId:number){
    const district=this.districtService.DistrictList.find(x=>x.District_id==districtId)
    return district!=null?district.District_Name:'Not Found'
   }
  getAllPropertys(){
    if(this.searchKey!==''&& this.propertiesService.selectedDistrictId==0){
      this.searchList= this.propertiesService.PropertiesList.filter(x=>x.House_Nbr.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
     if(this.searchKey!=='' && this.propertiesService.selectedDistrictId!=0){
      this.searchList= this.propertiesService.PropertiesList.filter(x=>x.House_Nbr.toLowerCase().includes(this.searchKey.toLocaleLowerCase())&&x.Zone_id==this.propertiesService.selectedZoneId&&x.district_id==this.propertiesService.selectedDistrictId)
      return this.searchList
     }
     if(this.propertiesService.selectedDistrictId!=0){
      this.searchList= this.propertiesService.PropertiesList.filter(x=>x.Zone_id==this.propertiesService.selectedZoneId&&x.district_id==this.propertiesService.selectedDistrictId)
      return this.searchList
     }
    return this.propertiesService.PropertiesList
  }



  print(){
    window.print()
  }

  public openFilterDialog(){
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      maxWidth: "400px",
    });
  }

  public openPropertyDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(PropertyDialogComponent, {
    data: {
      property:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(Property => {
    if(Property){
      const index: number = this.getAllPropertys().findIndex(x => x.Property_id == Property.Property_id);
        console.log(index)
        if(index !== -1){
          this.propertiesService.updateProperty(Property)
          this.getAllPropertys()[index] = Property
          this.openSnackBar("Property information updated successfully","ok")
          return
        }
        else{
          this.getAllPropertys().push(Property)
          this.propertiesService.addProperty(Property)
          this.openSnackBar("New Property has been added successfully","ok")
          return
        }
    }

  }
  );
}



public remove(Property:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this Property?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllPropertys().indexOf(Property);
      if (index !== -1) {
        this.propertiesService.deleteProperty(Property.Property_id)
        this.getAllPropertys().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}
