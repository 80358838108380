<div class="wrapper">
  <div *ngIf="!loginValid" class="top-section">
    <span class="error message">We couldn’t find an account matching the username and password you entered. Please check your username and password and try again.</span>
  </div>
  <div class="log-page">
    <div class="left-section">
      <img src="./assets/property.jpg"/>
    </div>
    <div class="right-section">


      <div class="signin-title">
        <div class="divider"></div>
        <h4>Sign in to MGQ TAX</h4>
        <div class="divider"></div>
      </div>

      <form class="form-wrapper" [formGroup]="loginForm" (ngSubmit)="onLogin()">
        <mat-form-field appearance="outline" class="custom-form-field">
          <mat-label>User Name</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="login.UserName"
            placeholder="Email"
            placeholder="Use Name"
            formControlName="username"
          />
          <mat-icon matPrefix>person</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input
            [type]="hide ? 'password' : 'text'"
            matInput
            [(ngModel)]="login.Password"
            placeholder="Password"
            formControlName="password"
          />
          <mat-icon matPrefix>lock</mat-icon>
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{
              hide ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>


        <button
          type="submit"
          align="center"
          class="login-btn"
          id="login_btn"
          [disabled]="!loginForm.valid"
        >
           Sign in
        </button>
        <span>Version 1.0.10</span>
      </form>
    </div>
  </div>
</div>
