import { Component, OnInit } from '@angular/core';
import { District } from './district';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { CitiesService } from '../cities/cities.service';
import { DistrictsService } from './districts.service';
import { DistrictDialogComponent } from './district-dialog/district-dialog.component';

@Component({
  selector: 'app-districts',
  templateUrl: './districts.component.html',
  styleUrls: ['./districts.component.scss']
})
export class DistrictsComponent implements OnInit {

  searchKey:string=''
  searchList:District[]=[]
  public settings:Settings;
  constructor(public DistrictService:DistrictsService, public CityService:CitiesService,public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.CityService.getCities()
    this.DistrictService.getDistricts()
  }

  getAllDistricts(){
    if(this.searchKey!==''){
      this.searchList= this.DistrictService.DistrictList.filter(x=>x.District_Name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.DistrictService.DistrictList
  }


  getCity(id:number){
    const City=this.CityService.CityList.find(x=>x.City_id==id)
    return City!=null?City.City_Name:'Not Found'
  }

  public openDistrictDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(DistrictDialogComponent, {
    data: {
      district:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(District => {
    if(District){
      const index: number = this.getAllDistricts().findIndex(x => x.District_id == District.District_id);
        console.log(index)
        if(index !== -1){
          this.DistrictService.updateDistrict(District)
          this.getAllDistricts()[index] = District
          this.openSnackBar("District information updated successfully","ok")
          return
        }
        else{
          this.getAllDistricts().push(District)
          this.DistrictService.addDistrict(District)
          this.openSnackBar("New District has been added successfully","ok")
          return
        }
    }

  }
  );
}



public remove(District:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this District?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllDistricts().indexOf(District);
      if (index !== -1) {
        this.DistrictService.deleteDistrict(District.District_id)
        this.getAllDistricts().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}
