import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { State } from './state';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class StatesService {

  baseUrl=environment.host
  StateList:State[]=[]
  loading=false
  constructor(private http:HttpClient,private _snackBar:MatSnackBar) { }


  // State

 //get States
 getStates(){
  this.loading=true
  var url=this.baseUrl+'states'
  this.http.get(url).subscribe((data:State[])=>{
    this.StateList=data
    console.log(this.StateList)
    this.loading=false
  })
 }



 //add State
 addState(State:State){
  var url=this.baseUrl+'states'
  this.http.post(url,State).subscribe((data)=>{
    console.log(data)
  })
 }

 //update State
 updateState(State:State){

  var url=`${this.baseUrl}states/${State.State_id}`
  this.http.put(url,State).subscribe((data)=>{
    console.log(data)
    //this.StateList=data['countries']
  })
 }

  //delete State
  deleteState(id:string){
    var url=`${this.baseUrl}states/${id}`
    this.http.delete(url).subscribe((data)=>{
      //this.StateList=data['countries']
    })
  }
}
