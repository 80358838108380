import { Injectable } from '@angular/core';
import { Rental } from './rental';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RentalsService {

  baseUrl=environment.host
  RentalsList:Rental[]=[]
  loading=false
  constructor(private http:HttpClient,private _snackBar:MatSnackBar) { }


  // Rental

 //get Rentals
 getAllRentals(){
  this.loading=true
  var url=this.baseUrl+'rentals'
  this.http.get(url).subscribe((data:Rental[])=>{
    this.RentalsList=data
    this.loading=false
  })
 }


 //get Rentals of property A
 getPropertyAllRentals(propertyId:number){
  this.loading=true
  var url=`${this.baseUrl}/rentals/byPropertyId/${propertyId}`
  this.http.get(url).subscribe((data:Rental[])=>{
    this.RentalsList=data
    this.loading=false
  })
 }


 //add Rental
 addRental(Rental:Rental){
  var url=`${this.baseUrl}rentals`
  this.http.post(url,Rental).subscribe((data)=>{
    console.log(data)
  })
 }

 //update Rental
 updateRental(Rental:Rental){
  var url=`${this.baseUrl}rentals/${Rental.Rental_id}`
  this.http.put(url,Rental).subscribe((data)=>{
    console.log(data)
    //this.RentalList=data['countries']
  })
 }

  //delete Rental
  deleteRental(id:string){
    var url=`${this.baseUrl}rentals/${id}`
    this.http.delete(url).subscribe((data)=>{
      console.log(data)
      //this.RentalList=data['countries']
    })
  }
}
