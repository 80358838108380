import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-owner-dialog',
  templateUrl: './owner-dialog.component.html',
  styleUrls: ['./owner-dialog.component.scss']
})
export class OwnerDialogComponent implements OnInit {

  public form: UntypedFormGroup;
  constructor(public dialogRef: MatDialogRef<OwnerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _authService: AuthService,
    public fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      Owner_id: 0,
      Full_Name: ['', Validators.required],
      Mobile: ['', Validators.required],
      Email: ['', Validators.required],
      City: 'Mogadishu',
      Country: 'Somalia',
      Owner_Type: ['', Validators.required],
      Contact_Person: ['', Validators.required],
      Contact_Person_Mobile: ['', Validators.required],
      Last_Updated: new Date(),
      UserID: this._authService.userId
    });
   this.form.patchValue(this.data.owner);
  }


  setOwnerType(type:String){
    this.form.controls.Owner_Type.setValue(type)
  }
  public onSubmit(){
  console.log(this.form.valid)
  console.log(this.form.value)
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }


}

