import { Component, Inject, OnInit } from '@angular/core';
import { OwnersService } from '../../owners/owners.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Owner } from '../../owners/owner';
import { AuthService } from 'src/app/auth/services/auth.service';
import { District } from '../../districts/district';
import { DistrictsService } from '../../districts/districts.service';
import { ZonesService } from '../../zones/zones.service';
import { Zone } from '../../zones/zone';

@Component({
  selector: 'app-property-dialog',
  templateUrl: './property-dialog.component.html',
  styleUrls: ['./property-dialog.component.scss']
})
export class PropertyDialogComponent implements OnInit {

  public form: UntypedFormGroup;
  searchOwnerIndex:string=''
  searchOwnerList:Owner[]=[]
  searchDistrictIndex:string=''
  searchDistrictList:District[]=[]
  searchZoneIndex:string=''
  searchZoneList:Zone[]=[]
  constructor(public dialogRef: MatDialogRef<PropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,public OwnerService:OwnersService,private districtService:DistrictsService,private zoneService:ZonesService ,private _authService: AuthService) { }

  ngOnInit(): void {
    this.OwnerService.getOwners()
    this.districtService.getDistricts()
    this.zoneService.getZones()
    this.form = this.fb.group({
      Property_id: 0,
      Zone_id: ['', Validators.required],
      district_id: ['', Validators.required],
      Property_type: '',
      Property_Usage: '',
      Is_Building: true,
      Owner_id: 0,
      House_Nbr: ['', Validators.required],
      Unit_Number: ['', Validators.required],
      Rental_income:['', Validators.required],
      Latitude: 0,
      Longitude: 0,
      Nbr_Rooms: ['', Validators.required],
      Meter_Square: 0,
      Is_Rented: false,
      Is_Multi_Storey: false,
      Last_Updated: new Date()
    });
   this.form.patchValue(this.data.property);
  }

  setIsRented(){
   this.form.controls.Is_Rented.setValue(!this.form.get('Is_Rented').value)
  }
  setIsMultiStorey(){
    this.form.controls.Is_Multi_Storey.setValue(!this.form.get('Is_Multi_Storey').value)
  }
  filterOwnerList(){
    if(this.searchOwnerIndex!==''){
      this.searchOwnerList= this.OwnerService.OwnerList.filter(x=>x.Full_Name.toLowerCase().includes(this.searchOwnerIndex.toLocaleLowerCase()))
      return this.searchOwnerList
     }
    return this.OwnerService.OwnerList
  }
  filterDistrictList(){
    if(this.searchDistrictIndex!==''){
      this.searchDistrictList= this.districtService.DistrictList.filter(x=>x.District_Name.toLowerCase().includes(this.searchDistrictIndex.toLocaleLowerCase()))
      return this.searchDistrictList
     }
    return this.districtService.DistrictList
  }
  filterZoneList(){
    if(this.searchZoneIndex!==''){
      this.searchZoneList= this.zoneService.ZoneList.filter(x=>x.Zone_Name.toLowerCase().includes(this.searchZoneIndex.toLocaleLowerCase())&&x.District_id==this.form.get('district_id').value)
      return this.searchZoneList
     }
     this.searchZoneList= this.zoneService.ZoneList.filter(x=>x.District_id==this.form.get('district_id').value)
    return this.searchZoneList
  }
  public onSubmit(){
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }


}

