import { Injectable } from '@angular/core';
import { City } from './city';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CitiesService {

  baseUrl=environment.host
  CityList:City[]=[]
  loading=false
  constructor(private http:HttpClient,private _snackBar:MatSnackBar) { }


  // City

 //get Cities
 getCities(){
  this.loading=true
  var url=this.baseUrl+'cities'
  this.http.get(url).subscribe((data:City[])=>{
    this.CityList=data
    this.loading=false
  })
 }



 //add City
 addCity(City:City){
  var url=`${this.baseUrl}cities`
  this.http.post(url,City).subscribe((data)=>{
    console.log(data)
  })
 }

 //update City
 updateCity(City:City){
  var url=`${this.baseUrl}cities/${City.City_id}`
  this.http.put(url,City).subscribe((data)=>{
    console.log(data)
    //this.CityList=data['countries']
  })
 }

  //delete City
  deleteCity(id:string){
    var url=`${this.baseUrl}cities/${id}`
    this.http.delete(url).subscribe((data)=>{
      console.log(data)
      //this.CityList=data['countries']
    })
  }
}
