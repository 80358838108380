import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { District } from '../../districts/district';
import { DistrictsService } from '../../districts/districts.service';

@Component({
  selector: 'app-zone-dialog',
  templateUrl: './zone-dialog.component.html',
  styleUrls: ['./zone-dialog.component.scss']
})
export class ZoneDialogComponent implements OnInit {


  public form: UntypedFormGroup;
  searchDistrictIndex:string=''
  searchDistrictList:District[]=[]
  constructor(public dialogRef: MatDialogRef<ZoneDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,public DistrictService:DistrictsService) { }

  ngOnInit(): void {
    this.DistrictService.getDistricts()
    this.form = this.fb.group({
      Zone_id: 0,
      Zone_Name: ['', Validators.required],
      District_id: ['', Validators.required],
    });
   this.form.patchValue(this.data.zone);
  }

  filterDistrictList(){
    if(this.searchDistrictIndex!==''){
      this.searchDistrictList= this.DistrictService.DistrictList.filter(x=>x.District_Name.toLowerCase().includes(this.searchDistrictIndex.toLocaleLowerCase()))
      return this.searchDistrictList
     }
    return this.DistrictService.DistrictList
  }
  public onSubmit(){
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }


}
