import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { PropertiesService } from '../properties/properties.service';
import { TaxPayment } from './tax-payment';
import { TaxPaymentDialogComponent } from './tax-payment-dialog/tax-payment-dialog.component';
import { TaxPaymentsService } from './tax-payments.service';
import { TaxInvoicesService } from '../tax-invoices/tax-invoices.service';
import { OwnersService } from '../owners/owners.service';
import { Owner } from '../owners/owner';
import { HttpClient } from '@angular/common/http';
import { TaxInvoice } from '../tax-invoices/tax-invoice';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-tax-payment',
  templateUrl: './tax-payment.component.html',
  styleUrls: ['./tax-payment.component.scss']
})
export class TaxPaymentComponent implements OnInit {

  searchKey:string=''
  searchList:TaxPayment[]=[]
  searchList1:Owner[]=[]
  public settings:Settings;
  public form: UntypedFormGroup;
  constructor(public fb: UntypedFormBuilder,private http:HttpClient,public OwnerService:OwnersService,public TaxPaymentService:TaxPaymentsService, public taxInvoiceService:TaxInvoicesService,public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.OwnerService.getOwners()
    this.form = this.fb.group({
      houseNo: [null, Validators.required],
      // year: [null, Validators.required],
      //quarter: [null, Validators.required],
    });
    //this.taxInvoiceService.getTaxInvoices()
    //this.TaxPaymentService.getTaxPayments()
  }

  getAllOwners(){
    if(this.searchKey!==''){
      this.searchList1= this.OwnerService.OwnerList.filter(x=>x.Full_Name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList1
     }
    return this.OwnerService.OwnerList
  }
  PendingTaxInvoiceList:any[]=[]
  baseUrl='https://mgs-tax.samesoft.co/api/'

  getTaxInvoicesToPay(){
   if(this.form.valid){
    let houseNo=this.form.get('houseNo').value
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth()
    const quarter = Math.floor(currentMonth / 3) + 1
    const year=currentDate.getFullYear()
    console.log(quarter+"Generate"+year)
    //let quarter=this.form.get('quarter').value
    //let year=this.form.get('year').value
    this.isLoading=true
    var url=`${this.baseUrl}taxInvoices/HousebyQuarterYear/${houseNo}/${quarter}/${year}`
    this.http.get(url).subscribe((data:any[])=>{
      this.PendingTaxInvoiceList=data
      if(this.PendingTaxInvoiceList.length==0){
        this.openSnackBar("There are no tax invoices to pay.","ok")
      }
      this.isLoading=false
    })
   }
  }
  getAllTaxPayments(){
    if(this.searchKey!==''){
      this.searchList= this.TaxPaymentService.TaxPaymentList.filter(x=>x.Tax_Inv_Nbr.toString().toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.TaxPaymentService.TaxPaymentList
  }


  getTaxInvoice(id:number){
    const TaxInvoice=this.taxInvoiceService.TaxInvoiceList.find(x=>x.Tax_Inv_Nbr==id)
    return TaxInvoice
  }

  isLoading=false
  currentOwner=''
  public openTaxPaymentDialog(taxInvoice:any,isNew:boolean){
  const dialogRef = this.dialog.open(TaxPaymentDialogComponent, {
      data: {
        TaxInvoice:taxInvoice,
      },
      panelClass: ['theme-dialog'],
      autoFocus: false,
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });
  dialogRef.afterClosed().subscribe(TaxPayment=>{
    if(TaxPayment){
      const index: number = this.PendingTaxInvoiceList.indexOf(TaxPayment);
      if (index !== -1) {
        this.PendingTaxInvoiceList.splice(index, 1)
      }
    // this.form.reset()
    }
  })
}


public openTaxPaymentDialog1(data:any,isNew:boolean){let name='';

const dialogRef = this.dialog.open(TaxPaymentDialogComponent, {
  data: {
    TaxPayment:data,
    isNew:isNew
  },
  panelClass: ['theme-dialog'],
  autoFocus: false,
  direction: (this.settings.rtl) ? 'rtl' : 'ltr'
});

dialogRef.afterClosed().subscribe(TaxPayment => {
  if(TaxPayment){
    const index: number = this.getAllTaxPayments().findIndex(x => x.Tax_Inv_Nbr == TaxPayment.Tax_Inv_Nbr);
      console.log(index)
      if(index !== -1){
        this.TaxPaymentService.updateTaxPayment(TaxPayment,this.getTaxInvoice(TaxPayment.Tax_Inv_Nbr))
        this.getAllTaxPayments()[index] = TaxPayment
        this.openSnackBar("TaxPayment information updated successfully","ok")
        return
      }
      else{
        this.getAllTaxPayments().push(TaxPayment)
        this.TaxPaymentService.addTaxPayment(TaxPayment,this.getTaxInvoice(TaxPayment.Tax_Inv_Nbr))
        this.openSnackBar("New TaxPayment has been added successfully","ok")
        return
      }
  }

}
);
}

public remove(TaxPayment:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this TaxPayment?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllTaxPayments().indexOf(TaxPayment);
      if (index !== -1) {
        this.TaxPaymentService.deleteTaxPayment(TaxPayment.Tax_Inv_Nbr)
        this.getAllTaxPayments().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}


