import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Owner } from './owner';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OwnersService {

  baseUrl=environment.host
  OwnerList:Owner[]=[]
  loading=false
  constructor(private http:HttpClient,private _snackBar:MatSnackBar) { }


  // Owner

 //get Owners
 getOwners(){
  this.loading=true
  var url=this.baseUrl+'owners'
  this.http.get(url).subscribe((data:Owner[])=>{
    this.OwnerList=data
    console.log(data)
    this.loading=false
  })
 }



 //add Owner
 addOwner(Owner:Owner){
  var url=`${this.baseUrl}owners`
  this.http.post(url,Owner).subscribe((data)=>{
    console.log(data)
  })
 }

 //update Owner
 updateOwner(Owner:Owner){
  var url=`${this.baseUrl}owners/${Owner.Owner_id}`
  this.http.put(url,Owner).subscribe((data)=>{
    console.log(data)
    //this.OwnerList=data['countries']
  })
 }

  //delete Owner
  deleteOwner(id:string){
    var url=`${this.baseUrl}owners/${id}`
    this.http.delete(url).subscribe((data)=>{
      console.log(data)
      //this.OwnerList=data['countries']
    })
  }
}


