
<h1 mat-dialog-title>Tax Payment</h1>


<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div >
          <!-- <div class="px-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Tax Invoice</mat-label>
                <mat-select formControlName="Tax_Inv_Nbr" (selectionChange)="onInvoiceSelect($event)">
                    <div class="search-space">
                        <mat-icon class="mx-2">search</mat-icon>
                        <input [(ngModel)]="searchTaxInvoiceIndex"  placeholder="Search.." [ngModelOptions]="{standalone: true}">
                      </div>
                    <mat-option *ngFor="let TaxInvoice of  filterTaxInvoicesList()" [value]="TaxInvoice.Tax_Inv_Nbr">
                      {{TaxInvoice.Tax_Inv_Nbr}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.controls.Tax_Inv_Nbr.errors?.required">Tax invoice is required</mat-error>
            </mat-form-field>
          </div> -->
          <!-- <div class="px-1">
            <mat-form-field class="input-container" appearance="outline" class="w-100">
              <mat-label>Payment Date</mat-label>
              <div class="textfield-container">
                <input matInput  [matDatepicker]="adatepicker" formControlName="Payment_date" placeholder="Enter payment date" name="payment">
                <mat-datepicker #adatepicker></mat-datepicker>
                <mat-icon (click)="adatepicker.open()">calendar_today</mat-icon>
              </div>
              <mat-error *ngIf="form.controls.Payment_date.errors?.required">Payment date is required</mat-error>
             </mat-form-field>
          </div> -->
          <div class="px-1">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Amount</mat-label>
              <input type="number" matInput placeholder="amount" formControlName="Amount" required>
              <mat-error *ngIf="form.controls.Amount.errors?.required">Amount is required</mat-error>
            </mat-form-field>
           </div>

        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close><mat-icon>close</mat-icon></button>
            <button  *ngIf="!loading" mat-raised-button color="primary" type="submit"><mat-icon>save</mat-icon></button>
            <button  *ngIf="loading" mat-raised-button color="primary" type="submit">
                <mat-icon><mat-spinner diameter="20" color="accent">
                </mat-spinner></mat-icon>
            </button>
        </div>
    </div>
</form>
