
import { LoginForm } from "./../models/LoginForm";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpApi } from "../http/http-api";

// const OAUTH_DATA = environment.host;
const OAUTH_DATA = '';
@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) {}
  token!: any;
  incorrectCredential: boolean = false;
  userId:number=0
  fullName:string=''
  email:string=''
  zoneId:number=0

  restoreData(){
    this.userId=Number(localStorage.getItem('userId'))
    this.zoneId=Number(localStorage.getItem('zoneId'))
    this.fullName=localStorage.getItem('fullName')
    this.email=localStorage.getItem('email')
  }

  register(userRequest: any): Observable<any> {
    const data = {
      code: userRequest.codigo,
      email: userRequest.email,
      password: userRequest.password,
    };

    return this.http.post(HttpApi.oauthLogin, data).pipe(
      map((response: any) => {
        return response;
      })
    );
  }


  loginWithUserCredentials(loginInfo: LoginForm): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");

    const body = new URLSearchParams();
    // body.set('grant_type', 'password');
    // body.set('client_id', OAUTH_DATA.client_id);
    // body.set('client_secret', OAUTH_DATA.client_secret);
    // body.set('UserName', username);
    // body.set('Password', password);
    // body.set('scope', OAUTH_DATA.scope);

    var url='https://mgs-tax.samesoft.co/api/auth/login'
    return this.http.post(url, loginInfo, { headers }).pipe(
      map((response: any) => {
        localStorage.setItem(
          "access_token",
          JSON.stringify(response.access_token)
        );
        // if (response.role != "Cashier") {
        //   localStorage.setItem(
        //     "access_token",
        //     JSON.stringify(response.accessToken)
        //   );
        //   localStorage.setItem("role", JSON.stringify(response.role));
        // }

        return response;
      })
    );
  }

  loginWithRefreshToken(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", "application/json");

    // const body = new URLSearchParams();
    // body.set('grant_type', 'refresh_token');
    // body.set('client_id', OAUTH_DATA.client_id);
    // body.set('client_secret', OAUTH_DATA.client_secret);
    // body.set('refresh_token', this.refreshToken);
    // body.set('scope', OAUTH_DATA.scope);

    return this.http.post(HttpApi.oauthLogin, { headers }).pipe(
      map((response: any) => {
        localStorage.setItem("access_token", JSON.stringify(response.token));
        return response;
      })
    );
  }

  haveAccess(roles: string[]): boolean {
    const userType = JSON.parse(localStorage.getItem("role"));
    return roles.includes(userType) ? true : false;
  }

  isLogged(): boolean {
    return JSON.parse(localStorage.getItem("isUserLoggedIn")) ? true : false;
  }

  logout(): void {
    localStorage.setItem('isUserLoggedIn',JSON.stringify(false));
    //window.location.reload();
  }

  get accessToken() {
    return localStorage["access_token"]
      ? JSON.parse(localStorage["access_token"])
      : null;
  }

  get refreshToken() {
    return localStorage["access_token"]
      ? JSON.parse(localStorage["access_token"]).refresh_token
      : null;
  }

  get roles() {
    return localStorage["roles"] ? JSON.parse(localStorage["roles"]) : null;
  }
}
