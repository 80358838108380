import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Zone } from './zone';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {
  baseUrl=environment.host
  ZoneList:Zone[]=[]
  loading=false
  constructor(private http:HttpClient,private _snackBar:MatSnackBar) { }


  // Zone

 //get Zones
 getZones(){
  this.loading=true
  var url=this.baseUrl+'zones'
  this.http.get(url).subscribe((data:Zone[])=>{
    this.ZoneList=data
    this.loading=false
  })
 }



 //add Zone
 addZone(Zone:Zone){
  var url=`${this.baseUrl}zones`
  this.http.post(url,Zone).subscribe((data)=>{
    console.log(data)
  })
 }

 //update Zone
 updateZone(Zone:Zone){
  var url=`${this.baseUrl}zones/${Zone.Zone_id}`
  this.http.put(url,Zone).subscribe((data)=>{
    console.log(data)
    //this.ZoneList=data['countries']
  })
 }

  //delete Zone
  deleteZone(id:string){
    var url=`${this.baseUrl}zones/${id}`
    this.http.delete(url).subscribe((data)=>{
      console.log(data)
      //this.ZoneList=data['countries']
    })
  }
}
