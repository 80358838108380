import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Property } from './property';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {

  baseUrl=environment.host
  PropertiesList:Property[]=[]
  selectedZoneId=0
  selectedDistrictId=0
  loading=false
  constructor(private http:HttpClient,private _snackBar:MatSnackBar) { }


  // Property

 //get Propertys
 getProperties(){
  this.loading=true
  var url=this.baseUrl+'properties'
  this.http.get(url).subscribe((data:Property[])=>{
    this.PropertiesList=data
    this.loading=false
  })
 }



 //add Property
 addProperty(Property:Property){
  var url=`${this.baseUrl}properties`
  this.http.post(url,Property).subscribe((data)=>{
    console.log(data)
  })
 }

 //update Property
 updateProperty(Property:Property){
  var url=`${this.baseUrl}properties/${Property.Property_id}`
  this.http.put(url,Property).subscribe((data)=>{
    console.log(data)
    //this.PropertyList=data['countries']
  })
 }

  //delete Property
  deleteProperty(id:string){
    var url=`${this.baseUrl}properties/${id}`
    this.http.delete(url).subscribe((data)=>{
      console.log(data)
      //this.PropertyList=data['countries']
    })
  }
}
