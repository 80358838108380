import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ZonesService } from '../zones/zones.service';
import { DistrictsService } from '../districts/districts.service';
import { PropertiesService } from '../properties/properties.service';
import { District } from '../districts/district';
import { Zone } from '../zones/zone';


@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  public form:UntypedFormGroup
  searchDistrictIndex:string=''
  searchDistrictList:District[]=[]
  searchZoneIndex:string=''
  searchZoneList:Zone[]=[]
  constructor(public dialogRef: MatDialogRef<FilterDialogComponent>,private fb:UntypedFormBuilder,public zoneService:ZonesService,public districtService:DistrictsService,private propertiesService:PropertiesService) { }

ngOnInit(): void {
  this.zoneService.getZones()
  this.districtService.getDistricts()
  this.form=this.fb.group({
    zoneId:['',Validators.required],
    districtId:['',Validators.required]
  })
  if(this.propertiesService.selectedZoneId!=0)this.form.controls['zoneId'].setValue(this.propertiesService.selectedZoneId)
  if(this.propertiesService.selectedDistrictId!=0)this.form.controls['districtId'].setValue(this.propertiesService.selectedDistrictId)
}

onFilter(): void {
 if(this.form.valid){
  this.propertiesService.selectedZoneId=this.form.value.zoneId
this.propertiesService.selectedDistrictId=this.form.value.districtId
this.dialogRef.close(true);
 }
}

filterDistrictList(){
  if(this.searchDistrictIndex!==''){
    this.searchDistrictList= this.districtService.DistrictList.filter(x=>x.District_Name.toLowerCase().includes(this.searchDistrictIndex.toLocaleLowerCase()))
    return this.searchDistrictList
   }
  return this.districtService.DistrictList
}
filterZoneList(){
  if(this.searchZoneIndex!==''){
    this.searchZoneList= this.zoneService.ZoneList.filter(x=>x.Zone_Name.toLowerCase().includes(this.searchZoneIndex.toLocaleLowerCase())&&x.District_id==this.form.get('district_id').value)
    return this.searchZoneList
   }
   this.searchZoneList= this.zoneService.ZoneList.filter(x=>x.District_id==this.form.get('districtId').value)
  return this.searchZoneList
}
onDismiss(): void {
this.dialogRef.close(false);
}
onReset(): void {
  this.propertiesService.selectedZoneId=0
  this.propertiesService.selectedDistrictId=0
  this.form.controls['zoneId'].setValue(this.propertiesService.selectedZoneId)
  this.form.controls['districtId'].setValue(this.propertiesService.selectedDistrictId)
}

}

