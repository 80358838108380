import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TaxInvoice } from './tax-invoice';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaxInvoicesService {

  baseUrl=environment.host
  TaxInvoiceList:TaxInvoice[]=[]
  PendingTaxInvoiceList:TaxInvoice[]=[]
  loading=false
  constructor(private http:HttpClient,private _snackBar:MatSnackBar) { }


  // TaxInvoice

 //get TaxInvoices
 getTaxInvoices(){
  this.loading=true
  var url=this.baseUrl+'taxInvoices'
  this.http.get(url).subscribe((data:TaxInvoice[])=>{
    this.TaxInvoiceList=data
    this.loading=false
  })
 }

 //get TaxInvoices by year and quarter
 getTaxInvoicesByQuarterandYear(quarter:number,year:number){
  this.loading=true
  var url=`${this.baseUrl}taxInvoices/byQuarterYear/${quarter}/${year}`
  this.http.get(url).subscribe((data:TaxInvoice[])=>{
    this.PendingTaxInvoiceList=data
    this.loading=false
  })
 }

 //get TaxInvoices by year and quarter
 filterTaxInvoicesByQuarterandYear(quarter:number,year:number){
  this.loading=true
  var url=`${this.baseUrl}taxInvoices/filterbyQuarterYear/${quarter}/${year}`
  this.http.get(url).subscribe((data:TaxInvoice[])=>{
    this.TaxInvoiceList=data
    this.loading=false
  })
 }

 //add TaxInvoice
 addTaxInvoice(TaxInvoice:TaxInvoice){
  var url=`${this.baseUrl}taxInvoices`
  this.http.post(url,TaxInvoice).subscribe((data)=>{
    console.log(data)
  })
 }

 //update TaxInvoice
 updateTaxInvoice(TaxInvoice:TaxInvoice){
  var url=`${this.baseUrl}taxInvoices/${TaxInvoice.Tax_Inv_Nbr}`
  this.http.put(url,TaxInvoice).subscribe((data)=>{
    console.log(data)
    //this.TaxInvoiceList=data['countries']
  })
 }

  //delete TaxInvoice
  deleteTaxInvoice(id:string){
    var url=`${this.baseUrl}taxInvoices/${id}`
    this.http.delete(url).subscribe((data)=>{
      console.log(data)
      //this.TaxInvoiceList=data['countries']
    })
  }
}
