import { Component, OnInit } from '@angular/core';
import { Zone } from './zone';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { DistrictsService } from '../districts/districts.service';
import { ZonesService } from './zones.service';
import { ZoneDialogComponent } from './zone-dialog/zone-dialog.component';

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.scss']
})
export class ZonesComponent implements OnInit {

  searchKey:string=''
  searchList:Zone[]=[]
  public settings:Settings;
  constructor(public ZoneService:ZonesService, public districtService:DistrictsService,public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.districtService.getDistricts()
    this.ZoneService.getZones()
  }

  getAllZones(){
    if(this.searchKey!==''){
      this.searchList= this.ZoneService.ZoneList.filter(x=>x.Zone_Name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.ZoneService.ZoneList
  }


  getDistrict(id:number){
    const district=this.districtService.DistrictList.find(x=>x.District_id==id)
    return district!=null?district.District_Name:'Not Found'
  }

  public openZoneDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(ZoneDialogComponent, {
    data: {
      zone:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(Zone => {
    if(Zone){
      const index: number = this.getAllZones().findIndex(x => x.Zone_id == Zone.Zone_id);
        console.log(index)
        if(index !== -1){
          this.ZoneService.updateZone(Zone)
          this.getAllZones()[index] = Zone
          this.openSnackBar("Zone information updated successfully","ok")
          return
        }
        else{
          this.getAllZones().push(Zone)
          this.ZoneService.addZone(Zone)
          this.openSnackBar("New Zone has been added successfully","ok")
          return
        }
    }

  }
  );
}



public remove(Zone:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this Zone?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllZones().indexOf(Zone);
      if (index !== -1) {
        this.ZoneService.deleteZone(Zone.Zone_id)
        this.getAllZones().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}
