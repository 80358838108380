<div class="top-section">
  <h1 mat-dialog-title></h1>
  <div class="reset-btn" (click)="onReset()">Reset</div>
</div>
<form class="wrapper" [formGroup]="form" (ngSubmit)="onFilter()" autocomplete="off">
  <div mat-dialog-content>

     <div class="info-section">
       <div class="inputs-section">



        <mat-form-field appearance="outline" class="w-100">
          <mat-label>District</mat-label>
          <mat-select formControlName="districtId">
              <div class="search-space">
                  <mat-icon class="mx-2">search</mat-icon>
                  <input [(ngModel)]="searchDistrictIndex"  placeholder="Search.." [ngModelOptions]="{standalone: true}">
                </div>
              <mat-option *ngFor="let district of  filterDistrictList()" [value]="district.District_id">
                {{district.District_Name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.districtId.errors?.required">District is required</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Zone</mat-label>
        <mat-select formControlName="zoneId">
            <div class="search-space">
                <mat-icon class="mx-2">search</mat-icon>
                <input [(ngModel)]="searchZoneIndex"  placeholder="Search.." [ngModelOptions]="{standalone: true}">
              </div>
            <mat-option *ngFor="let zone of  filterZoneList()" [value]="zone.Zone_id">
              {{zone.Zone_Name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls.zoneId.errors?.required">Zone is required</mat-error>
    </mat-form-field>


       </div>
     </div>


  </div>
  <div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
        <button mat-raised-button color="warn" (click)="onDismiss()">Cancel</button>
        <button mat-raised-button color="primary" (click)="onFilter()">Filter</button>
    </div>
</div>
</form>



