<div class="wrapper">
  <div class="circle">
    <mat-icon>check</mat-icon>
  </div>
  <span class="item-message">Number of properties invoiced: {{properties}}</span>
  <span class="item-message">Number of properties invoiced: {{amount}}</span>
  <span class="success-message">have succesfully posted</span>
  <div class="go-back" [routerLink]="['/admin/tax-posting']">
    <span>Go Back</span>
  </div>
</div>
