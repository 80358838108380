import { Component, OnInit } from '@angular/core';
import { TaxInvoice } from './tax-invoice';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { PropertiesService } from '../properties/properties.service';
import { TaxInvoiceDialogComponent } from './tax-invoice-dialog/tax-invoice-dialog.component';
import { TaxInvoicesService } from './tax-invoices.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-tax-invoices',
  templateUrl: './tax-invoices.component.html',
  styleUrls: ['./tax-invoices.component.scss']
})
export class TaxInvoicesComponent implements OnInit {

  searchKey:string=''
  searchList:TaxInvoice[]=[]
  public settings:Settings;
  public form: UntypedFormGroup;
  isLoading=false
  constructor(public TaxInvoiceService:TaxInvoicesService,public fb: UntypedFormBuilder, public PropertyService:PropertiesService,public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      year: [null, Validators.required],
      quarter: [null, Validators.required],
    });
    this.PropertyService.getProperties()
    this.TaxInvoiceService.getTaxInvoices()

  }

  getAllTaxInvoices(){
    if(this.searchKey!==''){
      this.searchList= this.TaxInvoiceService.TaxInvoiceList.filter(x=>x.Tax_Inv_Nbr.toString().toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.TaxInvoiceService.TaxInvoiceList
  }


  fetch(){
    if(this.form.valid){
      this.TaxInvoiceService.filterTaxInvoicesByQuarterandYear(this.form.value.quarter,this.form.value.year)
    }
  }
  reset(){
    this.TaxInvoiceService.getTaxInvoices()
    this.form.reset()
  }
  getProperty(id:number){
    const Property=this.PropertyService.PropertiesList.find(x=>x.Property_id==id)
    return Property!=null?Property.House_Nbr:'Not Found'
  }

  public openTaxInvoiceDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(TaxInvoiceDialogComponent, {
    data: {
      TaxInvoice:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(TaxInvoice => {
    if(TaxInvoice){
      const index: number = this.getAllTaxInvoices().findIndex(x => x.Tax_Inv_Nbr == TaxInvoice.Tax_Inv_Nbr);
        console.log(index)
        if(index !== -1){
          this.TaxInvoiceService.updateTaxInvoice(TaxInvoice)
          this.getAllTaxInvoices()[index] = TaxInvoice
          this.openSnackBar("TaxInvoice information updated successfully","ok")
          return
        }
        else{
          this.getAllTaxInvoices().push(TaxInvoice)
          this.TaxInvoiceService.addTaxInvoice(TaxInvoice)
          this.openSnackBar("New TaxInvoice has been added successfully","ok")
          return
        }
    }

  }
  );
}



public remove(TaxInvoice:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this TaxInvoice?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllTaxInvoices().indexOf(TaxInvoice);
      if (index !== -1) {
        this.TaxInvoiceService.deleteTaxInvoice(TaxInvoice.Tax_Inv_Nbr)
        this.getAllTaxInvoices().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}

