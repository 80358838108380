<h1 mat-dialog-title>City</h1>

<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div >
            <div  class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="City name" formControlName="City_Name" required>
                    <mat-error *ngIf="form.controls.City_Name.errors?.required">City name is required</mat-error>
                </mat-form-field>
            </div>

            <div class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Region</mat-label>
                  <mat-select formControlName="Region_id">
                      <div class="search-space">
                          <mat-icon class="mx-2">search</mat-icon>
                          <input [(ngModel)]="searchRegionIndex"  placeholder="Search.." [ngModelOptions]="{standalone: true}">
                        </div>
                      <mat-option *ngFor="let Region of  filterRegionList()" [value]="Region.Region_id">
                        {{Region.Region_Name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.controls.Region_id.errors?.required">Region is required</mat-error>
              </mat-form-field>
            </div>

        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close><mat-icon>close</mat-icon></button>
            <button  *ngIf="true" mat-raised-button color="primary" type="submit"><mat-icon>save</mat-icon></button>
            <button  *ngIf="false" mat-raised-button color="primary" type="submit">
                <mat-icon><mat-spinner diameter="20" color="accent">
                </mat-spinner></mat-icon>
            </button>
        </div>
    </div>
</form>
