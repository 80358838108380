


<div class="p-1">

  <mat-card class="p-0">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 p-1">
          <h2>Tax Payment</h2>

          <form [formGroup]="form">
            <div class="row-section">
              <div class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>House Number</mat-label>
                  <input type="text" matInput placeholder="house number" formControlName="houseNo" required>
                  <mat-error *ngIf="form.controls.houseNo.errors?.required">House number is required</mat-error>
                </mat-form-field>
               </div>
              <!--<div class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Year</mat-label>
                  <input type="number" matInput placeholder="year" formControlName="year" required>
                  <mat-error *ngIf="form.controls.year.errors?.required">Year is required</mat-error>
                </mat-form-field>
               </div>
               <div class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Quarter</mat-label>
                    <mat-select formControlName="quarter">
                        <mat-option *ngFor="let _ of [].constructor(4); let i=index" [value]="i+1">
                          {{i+1}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.controls.quarter.errors?.required">Quarter is required</mat-error>
                </mat-form-field>
              </div>
            -->
              <div class="mat-cell">
                <button *ngIf="!isLoading" mat-raised-button color="primary"  (click)="getTaxInvoicesToPay()" class="w-20" style="margin-top: 10px;">
                   View
              </button>
              <button  *ngIf="isLoading" mat-raised-button color="primary" class="w-20" style="margin-top: 10px;">
                <mat-icon><mat-spinner diameter="20" color="accent">
                </mat-spinner></mat-icon>
            </button>
              </div>
            </div>
          </form>

          <!-- <div fxLayout="row wrap" class="items-center">
              <div class="mx-3">
                  <input type="search" class="form-control h-9 p-1" placeholder="Search.." [(ngModel)]="searchKey"/>
                </div>

                <button  mat-raised-button color="primary"  (click)="openTaxPaymentDialog(null,true)" class="w-20">
                  <mat-icon>add_circle</mat-icon>
              </button>
          </div> -->



      </div>
      <mat-divider></mat-divider>
      <div class="mat-table admin-table">
          <div class="mat-header-row">
              <div class="mat-header-cell">Tax Invoice No</div>
              <div class="mat-header-cell">House Number</div>
              <div class="mat-header-cell">Amount</div>
              <div class="mat-header-cell">Actions</div>
          </div>

           <div>
            <div  *ngFor="let taxInvoice of PendingTaxInvoiceList | paginate: { itemsPerPage: count, currentPage: page }" class="mat-row">
              <div class="mat-cell">{{taxInvoice.tax_inv_nbr}}</div>
              <div class="mat-cell">{{taxInvoice.House_Nbr}}</div>
             <div class="mat-cell">{{taxInvoice.amount}}</div>
             <div class="mat-cell">
              <button  mat-raised-button color="primary"  (click)="openTaxPaymentDialog(taxInvoice)" class="w-20">
                Payment
              </button>
             </div>
         </div>
         </div>

          <!-- <div *ngIf="!TaxPaymentService.loading">
             <div  *ngFor="let TaxPayment of getAllTaxPayments() | paginate: { itemsPerPage: count, currentPage: page }" class="mat-row">
              <div class="mat-cell">{{TaxPayment.Tax_Inv_Nbr}}</div>
              <div class="mat-cell">{{TaxPayment.Amount}}</div>
              <div class="mat-cell">{{TaxPayment.Payment_date|date}}</div>
              <div class="mat-cell">
                  <button mat-mini-fab color="primary" class="mx-2" matTooltip="Edit" (click)="openTaxPaymentDialog(TaxPayment,false)">
                      <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-mini-fab color="warn" matTooltip="Delete" class="mx-1" (click)="remove(TaxPayment)">
                      <mat-icon>delete</mat-icon>
                  </button>
              </div>
          </div>
          </div> -->
      </div>
  </mat-card>

  <div *ngIf="getAllOwners().length > 6" fxLayout="row wrap">
      <div fxFlex="100">
          <mat-card class="p-2 text-center">
              <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)"></pagination-controls>
          </mat-card>
      </div>
  </div>
</div>
