import { Component, OnInit } from '@angular/core';
import { City } from './city';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { RegionsService } from '../regions/regions.service';
import { CitiesService } from './cities.service';
import { CityDialogComponent } from './city-dialog/city-dialog.component';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {

  searchKey:string=''
  searchList:City[]=[]
  public settings:Settings;
  constructor(public CityService:CitiesService, public RegionService:RegionsService,public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.RegionService.getRegions()
    this.CityService.getCities()
  }

  getAllCities(){
    if(this.searchKey!==''){
      this.searchList= this.CityService.CityList.filter(x=>x.City_Name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.CityService.CityList
  }


  getRegion(id:number){
    const Region=this.RegionService.RegionList.find(x=>x.Region_id==id)
    return Region!=null?Region.Region_Name:'Not Found'
  }

  public openCityDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(CityDialogComponent, {
    data: {
      city:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(City => {
    if(City){
      const index: number = this.getAllCities().findIndex(x => x.City_id == City.City_id);
        console.log(index)
        if(index !== -1){
          this.CityService.updateCity(City)
          this.getAllCities()[index] = City
          this.openSnackBar("City information updated successfully","ok")
          return
        }
        else{
          this.getAllCities().push(City)
          this.CityService.addCity(City)
          this.openSnackBar("New City has been added successfully","ok")
          return
        }
    }

  }
  );
}



public remove(City:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this City?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllCities().indexOf(City);
      if (index !== -1) {
        this.CityService.deleteCity(City.City_id)
        this.getAllCities().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}
