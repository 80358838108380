import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgmCoreModule } from '@agm/core';

import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';

import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.url +'/assets/i18n/', '.json');
}

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { TopMenuComponent } from './theme/components/top-menu/top-menu.component';
import { MenuComponent } from './theme/components/menu/menu.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';

import { AppSettings } from './app.settings';
import { AppService } from './app.service';
import { AppInterceptor } from './theme/utils/app-interceptor';
import { OptionsComponent } from './theme/components/options/options.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { LoginComponent } from './auth/pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatesComponent } from './admin/states/states.component';
import { StatesDialogComponent } from './admin/states/states-dialog/states-dialog.component';
import { RegionsComponent } from './admin/regions/regions.component';
import { RegionsDialogComponent } from './admin/regions/regions-dialog/regions-dialog.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RolesComponent } from './admin/roles/roles.component';
import { RoleDialogComponent } from './admin/roles/role-dialog/role-dialog.component';
import { DistrictDialogComponent } from './admin/districts/district-dialog/district-dialog.component';
import { DistrictsComponent } from './admin/districts/districts.component';
import { ZoneDialogComponent } from './admin/zones/zone-dialog/zone-dialog.component';
import { ZonesComponent } from './admin/zones/zones.component';
import { CitiesComponent } from './admin/cities/cities.component';
import { CityDialogComponent } from './admin/cities/city-dialog/city-dialog.component';
import { OwnerDialogComponent } from './admin/owners/owner-dialog/owner-dialog.component';
import { OwnersComponent } from './admin/owners/owners.component';
import { PropertiesComponent } from './admin/properties/properties.component';
import { PropertyDialogComponent } from './admin/properties/property-dialog/property-dialog.component';
import { TaxInvoicesComponent } from './admin/tax-invoices/tax-invoices.component';
import { TaxInvoiceDialogComponent } from './admin/tax-invoices/tax-invoice-dialog/tax-invoice-dialog.component';
import { TaxPaymentDialogComponent } from './admin/tax-payment/tax-payment-dialog/tax-payment-dialog.component';
import { TaxPaymentComponent } from './admin/tax-payment/tax-payment.component';
import { TaxPostingComponent } from './admin/tax-posting/tax-posting.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { RentalDialogComponent } from './admin/rentals/rental-dialog/rental-dialog.component';
import { RentalsComponent } from './admin/rentals/rentals.component';
import { FilterDialogComponent } from './admin/filter-dialog/filter-dialog.component';
import { OverviewComponent } from './admin/overview/overview.component';


@NgModule({
   imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  declarations: [
    AppComponent,
    TopMenuComponent,
    MenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent,
    LoginComponent,
    StatesComponent,
    StatesDialogComponent,
    RegionsComponent,
    RegionsDialogComponent,
    RolesComponent,
    RoleDialogComponent,
    ZonesComponent,
    ZoneDialogComponent,
    DistrictDialogComponent,
    DistrictsComponent,
    CitiesComponent,
    CityDialogComponent,
    OwnersComponent,
    OwnerDialogComponent,
    PropertiesComponent,
    PropertyDialogComponent,
    TaxInvoicesComponent,
    TaxInvoiceDialogComponent,
    TaxPaymentComponent,
    TaxPaymentDialogComponent,
    TaxPostingComponent,
    RentalsComponent,
    RentalDialogComponent,
    FilterDialogComponent,
    OverviewComponent
  ],
  providers: [
    AppSettings,
    AppService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
