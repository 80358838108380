import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StatesService } from '../states.service';

@Component({
  selector: 'app-states-dialog',
  templateUrl: './states-dialog.component.html',
  styleUrls: ['./states-dialog.component.scss']
})
export class StatesDialogComponent implements OnInit {

  public form: UntypedFormGroup;
  searchCountryIndex:string=''
  //searchCountryList:country[]=[]
  constructor(public dialogRef: MatDialogRef<StatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,public stateService:StatesService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      State_id: 0,
      State_Name: ['', Validators.required],
      Country: ['', Validators.required],
    });
   this.form.patchValue(this.data.state);
  }


  // filterCountryList(){
  //   if(this.searchCountryIndex!==''){
  //     this.searchCountryList= this.masterDataService.countryList.filter(x=>x.name.toLowerCase().includes(this.searchCountryIndex.toLocaleLowerCase()))
  //     return this.searchCountryList
  //    }
  //   return this.masterDataService.countryList
  // }

  public onSubmit(){
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }


}

