import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CitiesService } from '../../cities/cities.service';
import { City } from '../../cities/city';

@Component({
  selector: 'app-district-dialog',
  templateUrl: './district-dialog.component.html',
  styleUrls: ['./district-dialog.component.scss']
})
export class DistrictDialogComponent implements OnInit {

  public form: UntypedFormGroup;
  searchCityIndex:string=''
  searchCityList:City[]=[]
  constructor(public dialogRef: MatDialogRef<DistrictDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,public CityService:CitiesService) { }

  ngOnInit(): void {
    this.CityService.getCities()
    this.form = this.fb.group({
      District_id: 0,
      District_Name: ['', Validators.required],
      City_id: ['', Validators.required],
    });
   this.form.patchValue(this.data.district);
  }

  filterCityList(){
    if(this.searchCityIndex!==''){
      this.searchCityList= this.CityService.CityList.filter(x=>x.City_Name.toLowerCase().includes(this.searchCityIndex.toLocaleLowerCase()))
      return this.searchCityList
     }
    return this.CityService.CityList
  }
  public onSubmit(){
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }


}
