import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tax-posting',
  templateUrl: './tax-posting.component.html',
  styleUrls: ['./tax-posting.component.scss']
})
export class TaxPostingComponent implements OnInit {

  loading=false
  noProperties!:number
  amountToPost!:number
  isViewLoading=false
  isPostLoading=false
  public form: UntypedFormGroup;
  constructor(private router:Router,public fb: UntypedFormBuilder,private http:HttpClient,private _snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      year: [null, Validators.required],
      quarter: [null, Validators.required],
    });
  }

  view(){
    if(this.form.valid){
      this.isViewLoading=true
      let url=`${environment.host}taxInvoices/get/tax-data?year=${this.form.get('year').value}&quarter=${this.form.get('quarter').value}`
      this.http.get(url).subscribe((data:any)=>{
        this.noProperties=data['property_count']
        this.amountToPost=data['total_tax_amount']
        this.isViewLoading=false
      })
    }

  }
  postTax(){
    if(this.noProperties && this.form.valid){
      this.isPostLoading=true
      let url=`${environment.host}taxInvoices/post/tax-invoice?year=${this.form.get('year').value}&quarter=${this.form.get('quarter').value}`

      this.http.get(url).subscribe((data:any)=>{
        //this.openSnackBar("Tax invoices posted.","ok")
        this.isPostLoading=false
        this.router.navigateByUrl(`/admin/overview/${this.noProperties}/${Math.round(this.amountToPost*100)/100}`);
      })
    }
  }

  openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }
}
