<h1 mat-dialog-title>Property</h1>


<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div >
            <div class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Owner</mat-label>
                  <mat-select formControlName="Owner_id">
                      <div class="search-space">
                          <mat-icon class="mx-2">search</mat-icon>
                          <input [(ngModel)]="searchOwnerIndex"  placeholder="Search.." [ngModelOptions]="{standalone: true}">
                        </div>
                      <mat-option *ngFor="let Owner of  filterOwnerList()" [value]="Owner.Owner_id">
                        {{Owner.Full_Name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.controls.Owner_id.errors?.required">Owner is required</mat-error>
              </mat-form-field>
            </div>

            <div class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>District</mat-label>
                  <mat-select formControlName="district_id">
                      <div class="search-space">
                          <mat-icon class="mx-2">search</mat-icon>
                          <input [(ngModel)]="searchDistrictIndex"  placeholder="Search.." [ngModelOptions]="{standalone: true}">
                        </div>
                      <mat-option *ngFor="let district of  filterDistrictList()" [value]="district.District_id">
                        {{district.District_Name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.controls.district_id.errors?.required">District is required</mat-error>
              </mat-form-field>
            </div>

            <div class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Zone</mat-label>
                  <mat-select formControlName="Zone_id">
                      <div class="search-space">
                          <mat-icon class="mx-2">search</mat-icon>
                          <input [(ngModel)]="searchZoneIndex"  placeholder="Search.." [ngModelOptions]="{standalone: true}">
                        </div>
                      <mat-option *ngFor="let zone of  filterZoneList()" [value]="zone.Zone_id">
                        {{zone.Zone_Name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.controls.Zone_id.errors?.required">Zone is required</mat-error>
              </mat-form-field>
            </div>

            <div class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Rental Income</mat-label>
                <input type="number" matInput placeholder="Rental income" formControlName="Rental_income" required>
                <mat-error *ngIf="form.controls.Rental_income.errors?.required">Rental_income is required</mat-error>
              </mat-form-field>
             </div>

            <div  class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>House Number</mat-label>
                  <input matInput placeholder="house number" formControlName="House_Nbr" required>
                  <mat-error *ngIf="form.controls.House_Nbr.errors?.required">House number is required</mat-error>
              </mat-form-field>
          </div>

          <div  class="px-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Unit Number</mat-label>
                <input matInput placeholder="unit number" formControlName="Unit_Number" required>
                <mat-error *ngIf="form.controls.Unit_Number.errors?.required">Unit number is required</mat-error>
            </mat-form-field>
        </div>

         <div class="px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Number of Rooms</mat-label>
            <input type="number" matInput placeholder="number of rooms" formControlName="Nbr_Rooms" required>
            <mat-error *ngIf="form.controls.Nbr_Rooms.errors?.required">Number of rooms is required</mat-error>
          </mat-form-field>
         </div>

         <div class="px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Meter Square</mat-label>
            <input type="number" matInput placeholder="meter square" formControlName="Meter_Square" required>
            <mat-error *ngIf="form.controls.Meter_Square.errors?.required">Meter square is required</mat-error>
          </mat-form-field>
         </div>

         <div class="radio-section">
          <input type="radio"  name="Individual" (click)="setIsRented()" [checked]="form.get('Is_Rented').value==true">
          <span>Is Rented</span>
          <input type="radio"  name="Coorporate" (click)="setIsMultiStorey()" [checked]="form.get('Is_Multi_Storey').value==true">
          <span>Is Multi Storey</span>
         </div>

        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close><mat-icon>close</mat-icon></button>
            <button  *ngIf="true" mat-raised-button color="primary" type="submit"><mat-icon>save</mat-icon></button>
            <button  *ngIf="false" mat-raised-button color="primary" type="submit">
                <mat-icon><mat-spinner diameter="20" color="accent">
                </mat-spinner></mat-icon>
            </button>
        </div>
    </div>
</form>
