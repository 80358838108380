import { Component, OnInit } from '@angular/core';
import { Role } from './role';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { StatesService } from '../states/states.service';
import { RolesService } from './roles.service';
import { RoleDialogComponent } from './role-dialog/role-dialog.component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  searchKey:string=''
  searchList:Role[]=[]
  public settings:Settings;
  constructor(public RoleService:RolesService, public stateService:StatesService,public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.stateService.getStates()
    this.RoleService.getRoles()
  }

  getAllRoles(){
    if(this.searchKey!==''){
      this.searchList= this.RoleService.RoleList.filter(x=>x.Role_Name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.RoleService.RoleList
  }


  getState(id:string){
    const state=this.stateService.StateList.find(x=>x.State_id==id)
    return state!=null?state.State_Name:'Not Found'
  }

  public openRoleDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(RoleDialogComponent, {
    data: {
      role:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(Role => {
    if(Role){
      const index: number = this.getAllRoles().findIndex(x => x.Role_ID == Role.Role_ID);
        console.log(index)
        if(index !== -1){
          this.RoleService.updateRole(Role)
          this.getAllRoles()[index] = Role
          this.openSnackBar("Role information updated successfully","ok")
          return
        }
        else{
          this.getAllRoles().push(Role)
          this.RoleService.addRole(Role)
          this.openSnackBar("New Role has been added successfully","ok")
          return
        }
    }

  }
  );
}



public remove(Role:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this Role?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllRoles().indexOf(Role);
      if (index !== -1) {
        this.RoleService.deleteRole(Role.Role_ID)
        this.getAllRoles().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}
