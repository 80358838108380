import { ThisReceiver } from "@angular/compiler";
import { MatGridTileHeaderCssMatStyler } from "@angular/material/grid-list";
import { environment } from "src/environments/environment";

export class HttpApi {
  // OAuth
  static oauthLogin = "casherAccount/login";

  // customer signin
  static customersignin = "auth/customer/login";

  //customer registeration
  static customerRegistration = "customerAccount/signup";

  //product categories
  static productBaseUrl = "products/";
  static createProductCategory = this.productBaseUrl + "categories/";
  static updateProductCategory = this.productBaseUrl + "categories/";
  static deleteProductCategory = this.productBaseUrl + "categories";
  static getProductCategorys = this.productBaseUrl + "categories/list";
  static getCategoryById = this.productBaseUrl + "category";

  //products
  static productsBaseUrl = "products/";
  static createProduct = this.productsBaseUrl + "addproduct/";
  static updateProduct = this.productsBaseUrl + "updateproduct/";
  static deleteProduct = this.productsBaseUrl + "removeproduct";
  static getProducts = this.productsBaseUrl + "getallproducts";
  static getProductCustomer = this.productsBaseUrl + "getallproducts/customer";
  static getproductCategoryById = this.productsBaseUrl + "productbyCategory";
  static getPopular = this.productBaseUrl + "popular";

  // Product List
  static productlist = this.productBaseUrl + "product_list";

  //product detail
  static getProductById = this.productsBaseUrl + "product_detail";

  // status Change
  static getStatusChange = this.productsBaseUrl + "availablityUpdate";

  // coupon
  static couponBaseUrl = "coupon";
  static createCoupon = this.couponBaseUrl;
  static updateCoupon = this.couponBaseUrl;
  static deleteCoupon = this.couponBaseUrl;
  static getCoupon = this.couponBaseUrl;
  static getCouponById = this.couponBaseUrl;

  //customers
  static customerBaseUrl = "customer/";
  static createCustomer = this.customerBaseUrl;
  static updateCustomer = this.customerBaseUrl;
  static deleteCustomer = this.customerBaseUrl;
  static getCustomer = this.customerBaseUrl;
  static getCustomerById = this.customerBaseUrl;

  //delivery fee
  static deliveryBaseUrl = "deliveryFee";
  static createDelivery = this.deliveryBaseUrl;
  static deleteDelivery = this.deliveryBaseUrl;
  static updateDelivery = this.deliveryBaseUrl;
  static getDelivery = this.deliveryBaseUrl;

  // WhishList
  static wishList = "wishList";

  // billing Address
  static placeOrderBaseUrl = "foodOrder";
  static getOrderByCallCenter = this.placeOrderBaseUrl + "/callCenter";

  static billingAddress = this.placeOrderBaseUrl;
  static getOrder = this.placeOrderBaseUrl;

  // Orders list
  static listOfOrder = "orders";

  // Rating submit forms
  static review = "reviews";

  // approve rating
  static approve = "approve";
  static unapprove = "approve";

  //variation
  static variation = "productSize";
  static getProductSize = this.variation;
  static deleteProductSize = this.variation;
  static updateProductSize = this.variation;
  static createProductSize = this.variation;

  static variationDetail = this.variation;

  //user end point
  static users = "casherAccount/register";
  static getUser = "casherAccount/user";

  //Location end point
  static locations = "restaurantLocation";

  static extraProducts = "extraProducts";
  static createExtraNote = this.extraProducts;
  static deleteExtraNote = this.extraProducts;
  static updateExtraNote = this.extraProducts;
  static getExtraNote = this.extraProducts;

  // add notes
  static productNote = "productNotes";
  static createproductNote = this.productNote;
  static deleteproductNote = this.productNote;
  static updateproductNote = this.productNote;
  static getproductNote = this.productNote;

  // static extraNote = "extraNotes";
}
