<h1 mat-dialog-title>Owner</h1>


<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div >
            <div  class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Full Name</mat-label>
                    <input matInput placeholder="Full name" formControlName="Full_Name" required>
                    <mat-error *ngIf="form.controls.Full_Name.errors?.required">Full name is required</mat-error>
                </mat-form-field>
            </div>

            <div  class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Mobile</mat-label>
                  <input matInput placeholder="mobile" formControlName="Mobile" required>
                  <mat-error *ngIf="form.controls.Mobile.errors?.required">Mobile is required</mat-error>
              </mat-form-field>
          </div>
          <div  class="px-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Email</mat-label>
                <input matInput placeholder="email" formControlName="Email" required>
                <mat-error *ngIf="form.controls.Email.errors?.required">Email is required</mat-error>
            </mat-form-field>
        </div>

        <div class="radio-section">
          <input type="radio"  name="Individual" (click)="setOwnerType('Individual')" [checked]="form.get('Owner_Type').value=='Individual'">
          <span>Individual</span>
          <input type="radio"  name="Coorporate" (click)="setOwnerType('Coorporate')" [checked]="form.get('Owner_Type').value=='Coorporate'">
          <span>Coorporate</span>
         </div>
         <mat-error style="margin-left: 20px;" *ngIf="form.controls.Owner_Type.errors?.required">Owner type is required</mat-error>

        <div  class="px-1" style="margin-top: 20px;">
          <mat-form-field appearance="outline" class="w-100">
              <mat-label>Contact Person</mat-label>
              <input matInput placeholder="contact person" formControlName="Contact_Person" required>
              <mat-error *ngIf="form.controls.Contact_Person.errors?.required">Contact person is required</mat-error>
          </mat-form-field>
      </div>
      <div  class="px-1">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Contact Person Mobile</mat-label>
            <input matInput placeholder="contact person mobile" formControlName="Contact_Person_Mobile" required>
            <mat-error *ngIf="form.controls.Contact_Person_Mobile.errors?.required">Contact person mobile is required</mat-error>
        </mat-form-field>
    </div>

        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close><mat-icon>close</mat-icon></button>
            <button  *ngIf="true" mat-raised-button color="primary" type="submit"><mat-icon>save</mat-icon></button>
            <button  *ngIf="false" mat-raised-button color="primary" type="submit">
                <mat-icon><mat-spinner diameter="20" color="accent">
                </mat-spinner></mat-icon>
            </button>
        </div>
    </div>
</form>
