import { Injectable } from '@angular/core';
import { TaxPayment } from './tax-payment';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TaxInvoice } from '../tax-invoices/tax-invoice';
import { TaxInvoicesService } from '../tax-invoices/tax-invoices.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaxPaymentsService {

  baseUrl=environment.host
  TaxPaymentList:TaxPayment[]=[]
  loading=false
  constructor(private http:HttpClient,private _snackBar:MatSnackBar,private taxInvoiceService:TaxInvoicesService) { }


  // TaxPayment

 //get TaxPayments
 getTaxPayments(){
  this.loading=true
  var url=this.baseUrl+'taxPayments'
  this.http.get(url).subscribe((data:TaxPayment[])=>{
    this.TaxPaymentList=data
    this.loading=false
  })
 }



 //add TaxPayment
 addTaxPayment(TaxPayment:TaxPayment,TaxInvoice:TaxInvoice){
  TaxInvoice.Amount_Paid=TaxPayment.Amount
  var url=`${this.baseUrl}taxPayments`
  this.http.post(url,TaxPayment).subscribe((data)=>{
    console.log(data)
    this.taxInvoiceService.updateTaxInvoice(TaxInvoice)
  })
 }

 //update TaxPayment
 updateTaxPayment(TaxPayment:TaxPayment,TaxInvoice:TaxInvoice){
  TaxInvoice.Amount_Paid=TaxPayment.Amount
  var url=`${this.baseUrl}taxPayments/${TaxPayment.Tax_Inv_Nbr}`
  this.http.put(url,TaxPayment).subscribe((data)=>{
    console.log(data)
    this.taxInvoiceService.updateTaxInvoice(TaxInvoice)
    //this.TaxPaymentList=data['countries']
  })
 }

  //delete TaxPayment
  deleteTaxPayment(id:string){
    var url=`${this.baseUrl}taxPayments/${id}`
    this.http.delete(url).subscribe((data)=>{
      console.log(data)
      //this.TaxPaymentList=data['countries']
    })
  }
}
