import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RegionsService } from '../regions.service';
import { StatesService } from '../../states/states.service';
import { State } from '../../states/state';

@Component({
  selector: 'app-regions-dialog',
  templateUrl: './regions-dialog.component.html',
  styleUrls: ['./regions-dialog.component.scss']
})
export class RegionsDialogComponent implements OnInit {

  public form: UntypedFormGroup;
  searchStateIndex:string=''
  searchStateList:State[]=[]
  constructor(public dialogRef: MatDialogRef<RegionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,public stateService:StatesService) { }

  ngOnInit(): void {
    this.stateService.getStates()
    this.form = this.fb.group({
      Region_id: 0,
      Region_Name: ['', Validators.required],
      State_id: ['', Validators.required],
    });
   this.form.patchValue(this.data.region);
  }

  filterStateList(){
    if(this.searchStateIndex!==''){
      this.searchStateList= this.stateService.StateList.filter(x=>x.State_Name.toLowerCase().includes(this.searchStateIndex.toLocaleLowerCase()))
      return this.searchStateList
     }
    return this.stateService.StateList
  }
  public onSubmit(){
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }


}
