


<div class="p-1">

  <mat-card class="p-0">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 p-1">
          <h2>Tax Invoices List</h2>


          <div fxLayout="row wrap" class="items-center">
              <!-- <div class="mx-3">
                  <input type="search" class="form-control h-9 p-1" placeholder="Search.." [(ngModel)]="searchKey"/>
              </div> -->

              <form [formGroup]="form">
                <div class="row-section">
                  <div class="px-1">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Year</mat-label>
                      <input type="number" matInput placeholder="year" formControlName="year" required>
                      <mat-error *ngIf="form.controls.year.errors?.required">Year is required</mat-error>
                    </mat-form-field>
                   </div>
                   <div class="px-1">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Quarter</mat-label>
                        <mat-select formControlName="quarter">
                            <mat-option *ngFor="let _ of [].constructor(4); let i=index" [value]="i+1">
                              {{i+1}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="form.controls.quarter.errors?.required">Quarter is required</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="mat-cell">
                    <button *ngIf="!TaxInvoiceService.loading" mat-raised-button color="primary"  (click)="fetch()" class="w-20" style="margin-top: 10px;margin-right: 10px;">
                       Fetch
                  </button>
                  <button *ngIf="!TaxInvoiceService.loading" mat-raised-button color="primary"  (click)="reset()" class="w-20" style="margin-top: 10px;">
                    Reset
                  </button>
                  <button  *ngIf="TaxInvoiceService.loading" mat-raised-button color="primary" class="w-20" style="margin-top: 10px;">
                    <mat-icon><mat-spinner diameter="20" color="accent">
                    </mat-spinner></mat-icon>
                </button>
                  </div>
                </div>
              </form>


          </div>



      </div>
      <mat-divider></mat-divider>
      <div class="mat-table admin-table">
          <div class="mat-header-row">
              <div class="mat-header-cell">House Number</div>
              <div class="mat-header-cell">Status</div>
              <div class="mat-header-cell">Invoice Amount</div>
              <div class="mat-header-cell">Amount Paid</div>
              <div class="mat-header-cell">Actions</div>
          </div>
          <button class="loading"  *ngIf="TaxInvoiceService.loading" color="primary">
              <app-loading-spinner></app-loading-spinner>
           </button>
          <div *ngIf="!TaxInvoiceService.loading">
             <div  *ngFor="let TaxInvoice of getAllTaxInvoices() | paginate: { itemsPerPage: count, currentPage: page }" class="mat-row">
              <div class="mat-cell">{{getProperty(TaxInvoice.Property_id)}}</div>
              <div class="mat-cell">{{TaxInvoice.Status}}</div>
              <div class="mat-cell">{{TaxInvoice.Invoice_Amount}}</div>
              <div class="mat-cell">{{TaxInvoice.Amount_Paid}}</div>
              <div class="mat-cell">
                  <button mat-mini-fab color="warn" matTooltip="Delete" class="mx-1" (click)="remove(TaxInvoice)">
                      <mat-icon>delete</mat-icon>
                  </button>
              </div>
          </div>
          </div>
      </div>
  </mat-card>

  <div *ngIf="getAllTaxInvoices().length > 6" fxLayout="row wrap">
      <div fxFlex="100">
          <mat-card class="p-2 text-center">
              <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)"></pagination-controls>
          </mat-card>
      </div>
  </div>
</div>
