import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';
import { District } from '../../districts/district';
import { DistrictsService } from '../../districts/districts.service';
import { Owner } from '../../owners/owner';
import { OwnersService } from '../../owners/owners.service';
import { PropertyDialogComponent } from '../../properties/property-dialog/property-dialog.component';
import { ZonesService } from '../../zones/zones.service';

@Component({
  selector: 'app-rental-dialog',
  templateUrl: './rental-dialog.component.html',
  styleUrls: ['./rental-dialog.component.scss']
})
export class RentalDialogComponent implements OnInit {

  public form: UntypedFormGroup;
  searchOwnerIndex:string=''
  searchOwnerList:Owner[]=[]
  searchDistrictIndex:string=''
  searchDistrictList:District[]=[]
  searchZoneIndex:string=''
  searchZoneList:Zone[]=[]
  constructor(public dialogRef: MatDialogRef<PropertyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,public OwnerService:OwnersService,private districtService:DistrictsService,private zoneService:ZonesService ,private _authService: AuthService) { }

  ngOnInit(): void {
    this.OwnerService.getOwners()
    this.districtService.getDistricts()
    this.zoneService.getZones()
    this.form = this.fb.group({
      Rental_id:0,
      Property_id: 0,
      Tenant_Name: ['', Validators.required],
      Tenant_Mobile: ['', Validators.required],
      Contact_Person: ['', Validators.required],
      Start_date: ['', Validators.required],
      End_date: ['', Validators.required],
      Status: '',
    });
   this.form.patchValue(this.data.rental);
   if(!this.data.isNew){
     this.form.controls['Start_date'].setValue(new Date(this.data.rental.Start_date))
     this.form.controls['End_date'].setValue(new Date(this.data.rental.End_date))
   }
   else{
    this.form.controls['Property_id'].setValue(this.data.Property_id)
   }
  }
  public onSubmit(){
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }


}

