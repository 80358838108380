import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Region } from './region';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegionsService {

  baseUrl=environment.host
  RegionList:Region[]=[]
  loading=false
  constructor(private http:HttpClient,private _snackBar:MatSnackBar) { }


  // Region

 //get Regions
 getRegions(){
  this.loading=true
  var url=this.baseUrl+'regions'
  this.http.get(url).subscribe((data:Region[])=>{
    this.RegionList=data
    this.loading=false
  })
 }



 //add Region
 addRegion(Region:Region){
  var url=`${this.baseUrl}regions`
  this.http.post(url,Region).subscribe((data)=>{
    console.log(data)
  })
 }

 //update Region
 updateRegion(Region:Region){
  var url=`${this.baseUrl}regions/${Region.Region_id}`
  this.http.put(url,Region).subscribe((data)=>{
    console.log(data)
    //this.RegionList=data['countries']
  })
 }

  //delete Region
  deleteRegion(id:string){
    var url=`${this.baseUrl}regions/${id}`
    this.http.delete(url).subscribe((data)=>{
      //this.RegionList=data['countries']
    })
  }
}

