import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PropertiesService } from '../../properties/properties.service';
import { Property } from '../../properties/property';

@Component({
  selector: 'app-tax-invoice-dialog',
  templateUrl: './tax-invoice-dialog.component.html',
  styleUrls: ['./tax-invoice-dialog.component.scss']
})
export class TaxInvoiceDialogComponent implements OnInit {

  searchPropertyIndex:string=''
  searchPropertyList:Property[]=[]
  public form: UntypedFormGroup;
  constructor(public dialogRef: MatDialogRef<TaxInvoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _authService: AuthService,
    public propertiesService:PropertiesService,
    public fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.propertiesService.getProperties()
    this.form = this.fb.group({
      Tax_Inv_Nbr: 0,
      Rental_id: [0, Validators.required],
      Invoice_Date: new Date(),
      Post_Per_id: 0,
      Invoice_Amount: 0,
      Amount_Paid: 0,
      Status: 'pending',
      UserID:this._authService.userId,
      Last_Update:new Date(),
    });
    this.form.patchValue(this.data.TaxInvoice)
    this.form.controls.Last_Update.setValue(new Date())
  }


  getPropertyRentalIncome(id:number){
    const Property=this.propertiesService.PropertiesList.find(x=>x.Property_id==id)
    return Property!=null?Property.Rental_income:0
  }

  filterPropertiesList(){
    if(this.searchPropertyIndex!==''){
      this.searchPropertyList= this.propertiesService.PropertiesList.filter(x=>x.House_Nbr.toLowerCase().includes(this.searchPropertyIndex.toLocaleLowerCase()))
      return this.searchPropertyList
     }
    return this.propertiesService.PropertiesList
  }

  public onSubmit(){
    if(this.form.valid){
      this.form.controls.Invoice_Amount.setValue(this.getPropertyRentalIncome(this.form.get('Rental_id').value)*0.05)
      this.dialogRef.close(this.form.value);
    }
  }
}


