import { Component, OnInit } from '@angular/core';
import { Owner } from './owner';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings, AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { CitiesService } from '../cities/cities.service';
import { OwnerDialogComponent } from './owner-dialog/owner-dialog.component';
import { OwnersService } from './owners.service';

@Component({
  selector: 'app-owners',
  templateUrl: './owners.component.html',
  styleUrls: ['./owners.component.scss']
})
export class OwnersComponent implements OnInit {

  searchKey:string=''
  searchList:Owner[]=[]
  public settings:Settings;
  constructor(public OwnerService:OwnersService, public CityService:CitiesService,public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.CityService.getCities()
    this.OwnerService.getOwners()
  }

  getAllOwners(){
    if(this.searchKey!==''){
      this.searchList= this.OwnerService.OwnerList.filter(x=>x.Full_Name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.OwnerService.OwnerList
  }


  getCity(id:number){
    const City=this.CityService.CityList.find(x=>x.City_id==id)
    return City!=null?City.City_Name:'Not Found'
  }

  public openOwnerDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(OwnerDialogComponent, {
    data: {
      owner:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(Owner => {
    if(Owner){
      const index: number = this.getAllOwners().findIndex(x => x.Owner_id == Owner.Owner_id);
        console.log(index)
        if(index !== -1){
          this.OwnerService.updateOwner(Owner)
          this.getAllOwners()[index] = Owner
          this.openSnackBar("Owner information updated successfully","ok")
          return
        }
        else{
          this.getAllOwners().push(Owner)
          this.OwnerService.addOwner(Owner)
          this.openSnackBar("New Owner has been added successfully","ok")
          return
        }
    }

  }
  );
}



public remove(Owner:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this Owner?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllOwners().indexOf(Owner);
      if (index !== -1) {
        this.OwnerService.deleteOwner(Owner.Owner_id)
        this.getAllOwners().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}

