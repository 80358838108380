

<div class="p-1">

  <mat-card class="p-0">
      <div class="top-section">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 p-1">
          <h2>Properties List</h2>


          <div fxLayout="row wrap" class="items-center">
              <div class="mx-3">
                  <input type="search" class="form-control h-9 p-1" placeholder="Search.." [(ngModel)]="searchKey"/>
                </div>

                <div class="filter" (click)="openFilterDialog()">
                  <mat-icon>filter_list</mat-icon>
                </div>
                <button  mat-raised-button color="primary"  (click)="openPropertyDialog(null,true)" class="w-20">
                  <mat-icon>add_circle</mat-icon>
                  <!-- Add -->
              </button>
              <div class="filter" (click)="print()">
                <mat-icon>print</mat-icon>
              </div>
          </div>



      </div>
      </div>
      <mat-divider></mat-divider>
      <div class="mat-table admin-table">
          <div class="mat-header-row">
              <div class="mat-header-cell">House Number</div>
              <div class="mat-header-cell">Rental Income</div>
              <div class="mat-header-cell">No. Rooms</div>
              <div class="mat-header-cell">Zone</div>
              <div class="mat-header-cell">District</div>
              <div class="mat-header-cell">Actions</div>
          </div>
          <button class="loading"  *ngIf="propertiesService.loading" color="primary">
              <app-loading-spinner></app-loading-spinner>
           </button>
          <div *ngIf="!propertiesService.loading">
             <div  *ngFor="let Property of getAllPropertys() | paginate: { itemsPerPage: count, currentPage: page }" class="mat-row">
              <div class="mat-cell">{{Property.House_Nbr}}</div>
              <div class="mat-cell">{{Property.Rental_income}}</div>
              <div class="mat-cell">{{Property.Nbr_Rooms}}</div>
              <div class="mat-cell">{{getZone(Property.Zone_id)}}</div>
              <div class="mat-cell">{{getDistrict(Property.district_id)}}</div>
              <div class="mat-cell">
                 <button [routerLink]="['/admin/rentals',Property.Property_id]" mat-mini-fab class="bg-green-400" matTooltip="View">
                  <mat-icon>visibility</mat-icon>
                 </button>
                  <button mat-mini-fab color="primary" class="mx-1" matTooltip="Edit" (click)="openPropertyDialog(Property,false)">
                      <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-mini-fab color="warn" matTooltip="Delete" class="mx-1" (click)="remove(Property)">
                      <mat-icon>delete</mat-icon>
                  </button>
              </div>
          </div>
          </div>
      </div>
  </mat-card>

  <div *ngIf="getAllPropertys().length > 6" fxLayout="row wrap">
      <div fxFlex="100">
          <mat-card class="p-2 text-center">
              <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)"></pagination-controls>
          </mat-card>
      </div>
  </div>
</div>
