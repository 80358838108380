import { Component, Inject, OnInit } from '@angular/core';
import { TaxInvoicesService } from '../../tax-invoices/tax-invoices.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/services/auth.service';
import { TaxInvoice } from '../../tax-invoices/tax-invoice';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tax-payment-dialog',
  templateUrl: './tax-payment-dialog.component.html',
  styleUrls: ['./tax-payment-dialog.component.scss']
})
export class TaxPaymentDialogComponent implements OnInit {

  searchTaxInvoiceIndex:string=''
  searchTaxInvoiceList:TaxInvoice[]=[]
  public form: UntypedFormGroup;
  loading=false
  constructor(public dialogRef: MatDialogRef<TaxPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _authService: AuthService,
    private http:HttpClient,
    private taxInvoiceService:TaxInvoicesService,
    public fb: UntypedFormBuilder
    ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      Amount: ['', [Validators.min(1),Validators.required]],
    });
    this.form.controls.Amount.setValue(this.data.TaxInvoice.amount)
    //if(this.data.TaxInvoice.Amount_Paid>0)this.form.controls.Amount.setValue(this.data.TaxInvoice.Amount_Paid)
  }



  filterTaxInvoicesList(){
    if(this.searchTaxInvoiceIndex!==''){
      this.searchTaxInvoiceList= this.data.TaxInvoiceList.filter(x=>x.Tax_Inv_Nbr.toString().toLowerCase().includes(this.searchTaxInvoiceIndex.toLocaleLowerCase()))
      return this.searchTaxInvoiceList
     }
    return this.data.TaxInvoiceList
  }

  onInvoiceSelect(invoiceNo:any){
   let TaxInvoice=this.getTaxInvoice(this.form.get('Tax_Inv_Nbr').value)
   if(TaxInvoice.Amount_Paid>0)this.form.controls.Amount.setValue(TaxInvoice.Amount_Paid)
  }
  getTaxInvoice(id:number){
    const TaxInvoice=this.data.TaxInvoiceList.find(x=>x.Tax_Inv_Nbr==id)
    return TaxInvoice
  }

  public onSubmit(){
    if(this.form.valid){
      this.loading=true
      var paymentUrl=`${environment.host}taxPayments`
      var payment={
        "tax_inv_nbr": this.data.TaxInvoice['tax_inv_nbr'],
        "property_id":this.data.TaxInvoice['property_id'],
        "quarter": this.data.TaxInvoice['QUARTER'],
        "year": this.data.TaxInvoice['year'],
        "amount": this.form.controls['Amount'].value
      }
      this.http.post(paymentUrl,payment).subscribe((data)=>{
        this.loading=false
        this.dialogRef.close(this.data.TaxInvoice);
      })

    }
  }
}



