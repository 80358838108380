import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Region } from '../../regions/region';
import { RegionsService } from '../../regions/regions.service';

@Component({
  selector: 'app-city-dialog',
  templateUrl: './city-dialog.component.html',
  styleUrls: ['./city-dialog.component.scss']
})
export class CityDialogComponent implements OnInit {

  public form: UntypedFormGroup;
  searchRegionIndex:string=''
  searchRegionList:Region[]=[]
  constructor(public dialogRef: MatDialogRef<CityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: UntypedFormBuilder,public RegionService:RegionsService) { }

  ngOnInit(): void {
    this.RegionService.getRegions()
    this.form = this.fb.group({
      City_id: 0,
      City_Name: ['', Validators.required],
      Region_id: ['', Validators.required],
    });
   this.form.patchValue(this.data.city);
  }

  filterRegionList(){
    if(this.searchRegionIndex!==''){
      this.searchRegionList= this.RegionService.RegionList.filter(x=>x.Region_Name.toLowerCase().includes(this.searchRegionIndex.toLocaleLowerCase()))
      return this.searchRegionList
     }
    return this.RegionService.RegionList
  }
  public onSubmit(){
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }


}
