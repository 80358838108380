import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { District } from './district';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistrictsService {

  baseUrl=environment.host
  DistrictList:District[]=[]
  loading=false
  constructor(private http:HttpClient,private _snackBar:MatSnackBar) { }


  // District

 //get Districts
 getDistricts(){
  this.loading=true
  var url=this.baseUrl+'districts'
  this.http.get(url).subscribe((data:District[])=>{
    this.DistrictList=data
    this.loading=false
  })
 }



 //add District
 addDistrict(District:District){
  var url=`${this.baseUrl}districts`
  this.http.post(url,District).subscribe((data)=>{
    console.log(data)
  })
 }

 //update District
 updateDistrict(District:District){
  var url=`${this.baseUrl}districts/${District.District_id}`
  this.http.put(url,District).subscribe((data)=>{
    console.log(data)
    //this.DistrictList=data['countries']
  })
 }

  //delete District
  deleteDistrict(id:string){
    var url=`${this.baseUrl}districts/${id}`
    this.http.delete(url).subscribe((data)=>{
      console.log(data)
      //this.DistrictList=data['countries']
    })
  }
}



