<h1 mat-dialog-title>Rental</h1>


<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div >
            <div  class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Tenant Name</mat-label>
                  <input matInput placeholder="tenant name" formControlName="Tenant_Name" required>
                  <mat-error *ngIf="form.controls.Tenant_Name.errors?.required">Tenant name is required</mat-error>
              </mat-form-field>
          </div>
          <div  class="px-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Tenant Mobile</mat-label>
                <input matInput placeholder="tenant mobile" formControlName="Tenant_Mobile" required>
                <mat-error *ngIf="form.controls.Tenant_Mobile.errors?.required">Tenant mobile is required</mat-error>
            </mat-form-field>
        </div>

        <div  class="px-1">
          <mat-form-field appearance="outline" class="w-100">
              <mat-label>Contact Person</mat-label>
              <input matInput placeholder="contact person" formControlName="Contact_Person" required>
              <mat-error *ngIf="form.controls.Contact_Person.errors?.required">Contact person is required</mat-error>
          </mat-form-field>
      </div>

      <div class="px-1">
        <mat-form-field class="input-container" appearance="outline" class="w-100">
          <mat-label>Start Date</mat-label>
          <div class="textfield-container">
            <input matInput  [matDatepicker]="startdatepicker" formControlName="Start_date" placeholder="start date" name="start date">
            <mat-datepicker #startdatepicker></mat-datepicker>
            <mat-icon (click)="startdatepicker.open()">calendar_today</mat-icon>
          </div>
          <mat-error *ngIf="form.controls.Start_date.errors?.required">Start date is required</mat-error>
      </mat-form-field>
     </div>

     <div class="px-1">
      <mat-form-field class="input-container" appearance="outline" class="w-100">
        <mat-label>End Date</mat-label>
        <div class="textfield-container">
          <input matInput  [matDatepicker]="enddatepicker" formControlName="End_date" placeholder="end date" name="end date">
          <mat-datepicker #enddatepicker></mat-datepicker>
          <mat-icon (click)="enddatepicker.open()">calendar_today</mat-icon>
        </div>
        <mat-error *ngIf="form.controls.End_date.errors?.required">End date is required</mat-error>
    </mat-form-field>
   </div>


        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close><mat-icon>close</mat-icon></button>
            <button  *ngIf="true" mat-raised-button color="primary" type="submit"><mat-icon>save</mat-icon></button>
            <button  *ngIf="false" mat-raised-button color="primary" type="submit">
                <mat-icon><mat-spinner diameter="20" color="accent">
                </mat-spinner></mat-icon>
            </button>
        </div>
    </div>
</form>
