import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Role } from './role';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  baseUrl=environment.host
  RoleList:any[]=[]
  loading=false
  constructor(private http:HttpClient,private _snackBar:MatSnackBar) { }


  // Role

 //get Roles
 getRoles(){
  this.loading=true
  var url=this.baseUrl+'roles'
  console.log(`Url:${url}`)
  this.http.get(url).subscribe((data:any)=>{
    this.RoleList=data
    console.log(data)
    this.loading=false
  })
 }




 //add Role
 addRole(Role:any){
  var url=this.baseUrl+'roles'
  this.http.post(url,Role).subscribe((data)=>{
    console.log(data)
  })
 }

 //update Role
 updateRole(Role:Role){
  var url=`${this.baseUrl}roles/${Role.Role_ID}`
  this.http.put(url,Role).subscribe((data)=>{
    console.log(data)
    //this.RoleList=data['countries']
  })
 }

  //delete Role
  deleteRole(id:string){
    var url=`${this.baseUrl}roles/${id}`
    this.http.delete(url).subscribe((data)=>{
      //this.RoleList=data['countries']
    })
  }
}
