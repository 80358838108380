import { LoginForm } from "./../../models/LoginForm";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, Subject, take, takeUntil } from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { MatInputModule } from "@angular/material/input";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public loginValid: boolean = true;

  hide = true;
  checked = false;
  disabled = false;
  loginForm: FormGroup;
  error='';
  login: LoginForm = {
    UserName: "",
    Password: "",
  };

  required: boolean = true;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService
  ) {}

  public ngOnInit(): void {
    // if (this._authService.isLogged()) {
    //   this._router.navigateByUrl("/admin");
    // }

    this.loginForm = new FormGroup({
      username: new FormControl(this.login.UserName, [
        Validators.required,
        Validators.minLength(4),
      ]),
      password: new FormControl(this.login.Password, [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }

  public onLogin(): void {
    this.loginValid = true;
    console.log(this.loginForm.value);
    this._authService
      .loginWithUserCredentials(this.login)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          console.log(data['userZones'].length>0)
          this._router.navigateByUrl("/admin");
          this._authService.userId=data['user']['UserID']
          localStorage.setItem('userId',data['user']['UserID'])
          if(data['userZones'].length>0){this._authService.zoneId=data['userZones'][0]['Zone_id']??''
          localStorage.setItem('zoneId',data['userZones'][0]['Zone_id']??'')}
          this._authService.fullName=data['user']['Full_Name']
          localStorage.setItem('fullName',data['user']['Full_Name'])
          this._authService.email=data['user']['Email']
          localStorage.setItem('email',data['user']['Email'])
          localStorage.setItem('isUserLoggedIn',JSON.stringify(true))
        },
        error: (_) => (this.loginValid = false),
      });
  }
}
