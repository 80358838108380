
<h1 mat-dialog-title>Tax Invoice</h1>


<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div >
          <div class="px-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Property</mat-label>
                <mat-select formControlName="Rental_id">
                    <div class="search-space">
                        <mat-icon class="mx-2">search</mat-icon>
                        <input [(ngModel)]="searchPropertyIndex"  placeholder="Search.." [ngModelOptions]="{standalone: true}">
                      </div>
                    <mat-option *ngFor="let Property of  filterPropertiesList()" [value]="Property.Property_id">
                      {{Property.House_Nbr}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.controls.Rental_id.errors?.required">Property is required</mat-error>
            </mat-form-field>
          </div>

        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close><mat-icon>close</mat-icon></button>
            <button  *ngIf="true" mat-raised-button color="primary" type="submit"><mat-icon>save</mat-icon></button>
            <button  *ngIf="false" mat-raised-button color="primary" type="submit">
                <mat-icon><mat-spinner diameter="20" color="accent">
                </mat-spinner></mat-icon>
            </button>
        </div>
    </div>
</form>
